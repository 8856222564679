import {
  type RouterHistory,
  type RouteRecordRaw,
  type RouteComponent,
  createWebHistory,
  createWebHashHistory
} from "vue-router";
import { router } from "./index";
import { isProxy, toRaw } from "vue";
import { useTimeoutFn } from "@vueuse/core";
import {
  isString,
  cloneDeep,
  isAllEmpty,
  intersection,
  storageLocal,
  isIncludeAllChildren
} from "@pureadmin/utils";
import { getConfig } from "@/config";
import { buildHierarchyTree } from "@/utils/tree";
import { userKey, type DataInfo } from "@/utils/auth";
import { type menuType, routerArrays } from "@/layout/types";
import { useMultiTagsStoreHook } from "@/store/modules/multi-tags";
import { usePermissionStoreHook } from "@/store/modules/permission";
const IFrame = () => import("@/views/comm/frame/frame.vue");
import { convtRout } from "@/utils/menutrans";
// https://cn.vitejs.dev/guide/features.html#glob-import
const routFile = import.meta.env.VITE_ROUTER_TYPE;
let modulesRoutes = import.meta.glob("/src/views/**/*.{vue,tsx}");
if (routFile == "trader") {
  modulesRoutes = import.meta.glob("/src/views/trader/**/*.{vue,tsx}");
}
if (routFile == "admin") {
  modulesRoutes = import.meta.glob("/src/views/admin/**/*.{vue,tsx}");
}

import { getAsyncRoutes } from "./api";
function handRank(routeInfo: any) {
  const { name, path, parentId, meta } = routeInfo;
  return isAllEmpty(parentId)
    ? isAllEmpty(meta?.rank) ||
      (meta?.rank === 0 && name !== "Home" && path !== "/")
      ? true
      : false
    : false;
}

function ascending(arr: any[]) {
  arr.forEach((v, index) => {
    if (handRank(v)) v.meta.rank = index + 2;
  });
  return arr.sort(
    (a: { meta: { rank: number } }, b: { meta: { rank: number } }) => {
      return a?.meta.rank - b?.meta.rank;
    }
  );
}

function filterTree(data: RouteComponent[]) {
  const newTree = cloneDeep(data).filter(
    (v: { meta: { showLink: boolean } }) => v.meta?.showLink !== false
  );
  newTree.forEach(
    (v: { children }) => v.children && (v.children = filterTree(v.children))
  );
  return newTree;
}

function filterChildrenTree(data: RouteComponent[]) {
  const newTree = cloneDeep(data).filter((v: any) => v?.children?.length !== 0);
  newTree.forEach(
    (v: { children }) => v.children && (v.children = filterTree(v.children))
  );
  return newTree;
}

function isOneOfArray(a: Array<string>, b: Array<string>) {
  return Array.isArray(a) && Array.isArray(b)
    ? intersection(a, b).length > 0
      ? true
      : false
    : true;
}

function filterNoPermissionTree(data: RouteComponent[]) {
  const currentRoles =
    storageLocal().getItem<DataInfo<number>>(userKey)?.roles ?? [];
  const newTree = cloneDeep(data).filter((v: any) =>
    isOneOfArray(v.meta?.roles, currentRoles)
  );
  newTree.forEach(
    (v: any) => v.children && (v.children = filterNoPermissionTree(v.children))
  );
  return filterChildrenTree(newTree);
}

function getParentPaths(value: string, routes: RouteRecordRaw[], key = "path") {
  function dfs(routes: RouteRecordRaw[], value: string, parents: string[]) {
    for (let i = 0; i < routes.length; i++) {
      const item = routes[i];
      if (item[key] === value) return parents;
      if (!item.children || !item.children.length) continue;
      parents.push(item.path);

      if (dfs(item.children, value, parents).length) return parents;
      parents.pop();
    }
    return [];
  }

  return dfs(routes, value, []);
}

function findRouteByPath(path: string, routes: RouteRecordRaw[]) {
  let res = routes.find((item: { path: string }) => item.path == path);
  if (res) {
    return isProxy(res) ? toRaw(res) : res;
  } else {
    for (let i = 0; i < routes.length; i++) {
      if (
        routes[i].children instanceof Array &&
        routes[i].children.length > 0
      ) {
        res = findRouteByPath(path, routes[i].children);
        if (res) {
          return isProxy(res) ? toRaw(res) : res;
        }
      }
    }
    return null;
  }
}

function addPathMatch() {
  if (!router.hasRoute("pathMatch")) {
    router.addRoute({
      path: "/:pathMatch(.*)",
      name: "pathMatch",
      redirect: "/error/404"
    });
  }
}

function handleAsyncRoutes(routeList) {
  if (routeList.length === 0) {
    usePermissionStoreHook().handleWholeMenus(routeList);
  } else {
    formatFlatteningRoutes(addAsyncRoutes(routeList)).map(
      (v: RouteRecordRaw) => {
        if (
          router.options.routes[0].children.findIndex(
            value => value.path === v.path
          ) !== -1
        ) {
          return;
        } else {
          router.options.routes[0].children.push(v);
          ascending(router.options.routes[0].children);
          if (!router.hasRoute(v?.name)) router.addRoute(v);
          const flattenRouters: any = router
            .getRoutes()
            .find(n => n.path === "/");
          router.addRoute(flattenRouters);
        }
      }
    );
    usePermissionStoreHook().handleWholeMenus(routeList);
  }
  if (!useMultiTagsStoreHook().getMultiTagsCache) {
    useMultiTagsStoreHook().handleTags("equal", [
      ...routerArrays,
      ...usePermissionStoreHook().flatteningRoutes.filter(
        v => v?.meta?.fixedTag
      )
    ]);
  }
  addPathMatch();
}

/** 测试 --- start */
// import rouList from "../data/routes.json"; // 临时方案
// function initRouter() {
//   if (getConfig()?.CachingAsyncRoutes) {
//     const key = "async-routes";
//     const asyncRouteList = storageLocal().getItem(key) as any;
//     if (asyncRouteList && asyncRouteList?.length > 0) {
//       return new Promise(resolve => {
//         handleAsyncRoutes(asyncRouteList);
//         resolve(router);
//       });
//     } else {
//       return new Promise(resolve => {
//         handleAsyncRoutes(cloneDeep(rouList.data)); // 临时方案
//         storageLocal().setItem(key, rouList.data); // 临时方案
//         resolve(router); // 临时方案
//         return; // 临时方案
//         getAsyncRoutes()
//           .then(({ data }) => {
//             handleAsyncRoutes(cloneDeep(data));
//             storageLocal().setItem(key, data);
//             resolve(router);
//           })
//           .catch(error => {
//             console.log("请求异常", error);
//           });
//       });
//     }
//   } else {
//     return new Promise(resolve => {
//       handleAsyncRoutes(cloneDeep(rouList.data)); // 临时方案
//       resolve(router); // 临时方案
//       return; // 临时方案
//       getAsyncRoutes().then(({ data }) => {
//         handleAsyncRoutes(cloneDeep(data));
//         resolve(router);
//       });
//     }).catch(error => {
//       console.log("请求异常02", error);
//     });
//   }
// }
/** 测试 --- end */

function initRouter() {
  let roleId = storageLocal().getItem<DataInfo<number>>(userKey)?.roles ?? [];
  if (typeof roleId === "string") {
    roleId = JSON.parse(roleId);
  }
  const id = roleId[0].toString();
  const account =
    storageLocal().getItem<DataInfo<number>>(userKey)?.account ?? null;
  const params = { roleId: id, account: account };
  console.log(roleId, "params", params);
  if (getConfig()?.CachingAsyncRoutes) {
    const key = "async-routes";
    const asyncRouteList = storageLocal().getItem(key) as any;
    if (asyncRouteList && asyncRouteList?.length > 0) {
      return new Promise(resolve => {
        handleAsyncRoutes(asyncRouteList);
        resolve(router);
      });
    } else {
      return new Promise(resolve => {
        getAsyncRoutes(params).then(({ data }) => {
          data = convtRout(data.menus);
          handleAsyncRoutes(cloneDeep(data));
          storageLocal().setItem(key, data);
          resolve(router);
        });
      });
    }
  } else {
    return new Promise(resolve => {
      getAsyncRoutes(params).then(({ data }) => {
        data = convtRout(data.menus);
        handleAsyncRoutes(cloneDeep(data));
        resolve(router);
      });
    });
  }
}

function formatFlatteningRoutes(routesList: RouteRecordRaw[]) {
  if (routesList.length === 0) return routesList;
  let hierarchyList = buildHierarchyTree(routesList);
  for (let i = 0; i < hierarchyList.length; i++) {
    if (hierarchyList[i].children) {
      hierarchyList = hierarchyList
        .slice(0, i + 1)
        .concat(hierarchyList[i].children, hierarchyList.slice(i + 1));
    }
  }
  return hierarchyList;
}

function formatTwoStageRoutes(routesList: RouteRecordRaw[]) {
  if (routesList.length === 0) return routesList;
  const newRoutesList: RouteRecordRaw[] = [];
  routesList.forEach((v: RouteRecordRaw) => {
    if (v.path === "/") {
      newRoutesList.push({
        component: v.component,
        name: v.name,
        path: v.path,
        redirect: v.redirect,
        meta: v.meta,
        children: []
      });
    } else {
      newRoutesList[0]?.children.push({ ...v });
    }
  });
  return newRoutesList;
}

/** 处理缓存路由（添加、删除、刷新） */
function handleAliveRoute({ name }: ToRouteType, mode?: string) {
  switch (mode) {
    case "add":
      usePermissionStoreHook().cacheOperate({
        mode: "add",
        name
      });
      break;
    case "delete":
      usePermissionStoreHook().cacheOperate({
        mode: "delete",
        name
      });
      break;
    case "refresh":
      usePermissionStoreHook().cacheOperate({
        mode: "refresh",
        name
      });
      break;
    default:
      usePermissionStoreHook().cacheOperate({
        mode: "delete",
        name
      });
      useTimeoutFn(() => {
        usePermissionStoreHook().cacheOperate({
          mode: "add",
          name
        });
      }, 100);
  }
}

function addAsyncRoutes(arrRoutes: Array<RouteRecordRaw>) {
  if (!arrRoutes || !arrRoutes.length) return;
  const modulesRoutesKeys = Object.keys(modulesRoutes);
  arrRoutes.forEach((v: RouteRecordRaw) => {
    v.meta.backstage = true;
    if (v?.children && v.children.length && !v.redirect)
      v.redirect = v.children[0].path;
    if (v?.children && v.children.length && !v.name)
      v.name = (v.children[0].name as string) + "Parent";
    if (v.meta?.frameSrc) {
      v.component = IFrame;
    } else {
      const index = v?.component
        ? modulesRoutesKeys.findIndex(ev => ev.includes(v.component as any))
        : modulesRoutesKeys.findIndex(ev => ev.includes(v.path));
      v.component = modulesRoutes[modulesRoutesKeys[index]];
    }
    if (v?.children && v.children.length) {
      addAsyncRoutes(v.children);
    }
  });
  return arrRoutes;
}

// function getHistoryMode(routerHistory): RouterHistory {
//   const historyMode = routerHistory.split(",");
//   const leftMode = historyMode[0];
//   const rightMode = historyMode[1];
//   if (historyMode.length === 1) {
//     if (leftMode === "hash") {
//       return createWebHashHistory("");
//     } else if (leftMode === "h5") {
//       return createWebHistory("");
//     }
//   } else if (historyMode.length === 2) {
//     if (leftMode === "hash") {
//       return createWebHashHistory(rightMode);
//     } else if (leftMode === "h5") {
//       return createWebHistory(rightMode);
//     }
//   }
// }

function getHistoryMode(routerHistory: string): RouterHistory {
  if (!routerHistory) {
    console.warn("No router history mode specified, fallback to hash mode");
    return createWebHashHistory("");
  }

  try {
    const historyMode = routerHistory.split(",");
    const leftMode = historyMode[0]?.toLowerCase();
    const rightMode = historyMode[1] || "";

    console.log("History Mode:", {
      raw: routerHistory,
      left: leftMode,
      right: rightMode
    });

    if (historyMode.length === 1) {
      if (leftMode === "hash") {
        return createWebHashHistory("");
      } else if (leftMode === "h5") {
        return createWebHistory("");
      }
    } else if (historyMode.length === 2) {
      if (leftMode === "hash") {
        return createWebHashHistory(rightMode);
      } else if (leftMode === "h5") {
        return createWebHistory(rightMode);
      }
    }

    // 如果没有匹配的模式，返回默认的 hash 模式
    console.warn(
      `Invalid router history mode: ${routerHistory}, fallback to hash mode`
    );
    return createWebHashHistory("");
  } catch (error) {
    console.error("Error in getHistoryMode:", error);
    return createWebHashHistory("");
  }
}

function getAuths(): Array<string> {
  return router.currentRoute.value.meta.auths as Array<string>;
}

function hasAuth(value: string | Array<string>): boolean {
  if (!value) return false;
  const metaAuths = getAuths();
  if (!metaAuths) return false;
  const isAuths = isString(value)
    ? metaAuths.includes(value)
    : isIncludeAllChildren(value, metaAuths);
  return isAuths ? true : false;
}

function handleTopMenu(route) {
  if (route?.children && route.children.length > 1) {
    if (route.redirect) {
      return route.children.filter(cur => cur.path === route.redirect)[0];
    } else {
      return route.children[0];
    }
  } else {
    return route;
  }
}

function getTopMenu(tag = false): menuType {
  const topMenu = handleTopMenu(
    usePermissionStoreHook().wholeMenus[0]?.children[0]
  );
  tag && useMultiTagsStoreHook().handleTags("push", topMenu);
  return topMenu;
}

export {
  hasAuth,
  getAuths,
  ascending,
  filterTree,
  initRouter,
  getTopMenu,
  addPathMatch,
  isOneOfArray,
  getHistoryMode,
  addAsyncRoutes,
  getParentPaths,
  findRouteByPath,
  handleAliveRoute,
  formatTwoStageRoutes,
  formatFlatteningRoutes,
  filterNoPermissionTree
};
