import { http } from "@/utils/http";

export const getAsyncRoutes = params => {
  console.log("c----params----", params);
  return http.request<Result>(
    "post",
    "/api/v1/tenant/usercenter/user/menuOwn",
    { data: params }
  );
};
